@if (account) {
<ion-split-pane (ionSplitPaneVisible)="toggleSplitPaneVisible($event)" when="xl" contentId="main-content">
  <ion-menu contentId="main-content" type="overlay">
    <ion-content id="menu" [class.web]="!platform.is('capacitor')" class="menu ion-padding">
      @if (!splitPaneVisible) {
      <img
        id="logo-mobile"
        [src]="isDark ? 'assets/img/logo-white.svg' : 'assets/img/logo-dark.svg'"
        alt="Logo Salomon"
      />
      } @if (!platform.is('capacitor')) {
      <ion-item class="title" button lines="inset" detail="false" (click)="clickUserProfile($event)">
        <app-picture-rounded
          [pointer]="true"
          [min]="true"
          [content]="accountImage.content"
          [defaultPicture]="'assets/img/account-default.png'"
        ></app-picture-rounded>
        <ion-list-header>
          <ion-label class="label label-md"> {{account.firstName + ' ' + account.lastName}} </ion-label>
        </ion-list-header>
        <ion-icon name="caret-down"></ion-icon>
      </ion-item>
      }

      <ion-content class="menu" style="background: none">
        <ion-list id="list-menu">
          @if (platform.is('capacitor')) { @for (page of mobileMenuRoutes; track $index) {
          <ion-menu-toggle autoHide="false">
            <ion-item
              button
              routerDirection="root"
              [routerLink]="[page.url]"
              lines="inset"
              [disabled]="page.disabledOffline && (isOffline || !isConnected)"
              detail="false"
              routerLinkActive="selected"
            >
              <ion-icon aria-hidden="true" slot="start" [name]="page.icon"></ion-icon>
              <ion-label class="label label-md">{{ page.title | translate }}</ion-label>
              @if (page.icon === 'notifications') {
              <ion-badge class="notifications" slot="end"> {{this.notificationLength}} </ion-badge>
              }
            </ion-item>
          </ion-menu-toggle>
          } } @else { @for (category of webMenuRoutes; track $index) { @if (categoryHasPermission(category)) {

          <ion-item-group>
            @if (category.title) {
            <ion-item-divider [class.dark-item-divider]="isDark" class="item item-md category-divider">
              <ion-item
                lines="inset"
                [class.dark-item]="isDark"
                class="item item-md category-item"
                button
                [detail]="true"
                [detailIcon]="category.opened ? 'caret-up' : 'caret-down'"
                (click)="toggleCategoryOpened(category)"
              >
                <ion-icon aria-hidden="true" slot="start" [name]="category.icon"></ion-icon>
                <ion-label class="label label-md">{{category.title | translate}}</ion-label>
              </ion-item>
            </ion-item-divider>
            } @if (category.opened) { @for (page of category.routes; track $index) { @if (routeHasPermission(page.url,
            page.queryParams)) {
            <ion-menu-toggle autoHide="false">
              <ion-item
                button
                routerDirection="root"
                [routerLink]="[page.url]"
                [queryParams]="page.queryParams"
                lines="inset"
                detail="false"
                routerLinkActive="selected"
                [style]="category.title ? 'margin-left: 1rem' : ''"
              >
                @if (page.customIcon) { <ion-icon aria-hidden="true" slot="start" [src]="page.icon"></ion-icon> } @else
                { <ion-icon aria-hidden="true" slot="start" [name]="page.icon"></ion-icon> }
                <ion-label class="label label-md">{{ page.title | translate }}</ion-label>
                @if (page.icon === 'notifications') {
                <ion-badge class="notifications" slot="end"> {{this.notificationLength}} </ion-badge>
                }
              </ion-item>
            </ion-menu-toggle>
            } } }
          </ion-item-group>
          } } }
        </ion-list>
        <div class="bottom-items">
          @if (platform.is('capacitor')) {
          <ion-item button lines="inset" detail="false" (click)="switchTheme()">
            <ion-icon aria-hidden="true" slot="start" [name]="isDark ? 'moon' : 'sunny'"></ion-icon>
            <ion-label class="label label-md">{{ 'SWITCH_THEME' | translate }}</ion-label>
          </ion-item>

          <ion-item
            [disabled]="platform.is('capacitor') && !isConnected"
            button
            lines="inset"
            detail="false"
            (click)="switchMode()"
          >
            @if (isOffline) {
            <ion-icon aria-hidden="true" slot="start" name="cloud-offline"></ion-icon>
            <ion-label class="label label-md">{{ 'SWITCH_MODE_ONLINE' | translate }}</ion-label>
            } @else {
            <ion-icon aria-hidden="true" slot="start" name="cloud-done"></ion-icon>
            <ion-label class="label label-md">{{ 'SWITCH_MODE_OFFLINE' | translate }}</ion-label>
            }
          </ion-item>
          <ion-item
            [disabled]="platform.is('capacitor') && (isOffline || !isConnected)"
            button
            lines="inset"
            detail="false"
            (click)="logoutUser()"
          >
            <ion-icon aria-hidden="true" slot="start" name="log-out"></ion-icon>
            <ion-label class="label label-md">{{ 'LOGOUT' | translate }}</ion-label>
          </ion-item>
          } @else {
          <ion-item button lines="inset" detail="false" (click)="toTermsOfUse()">
            <ion-icon aria-hidden="true" slot="start" name="reader"></ion-icon>
            <ion-label class="label label-md">{{ 'TERMS_OF_USE' | translate }}</ion-label>
          </ion-item>
          <ion-item button lines="inset" detail="false" (click)="toPrivacyPolicy()">
            <ion-icon aria-hidden="true" slot="start" name="reader"></ion-icon>
            <ion-label class="label label-md">{{ 'PRIVACY_POLICY' | translate }}</ion-label>
          </ion-item>
          }
        </div>
      </ion-content>
    </ion-content>
    @if (!platform.is('capacitor')) {
    <div class="divider"></div>
    }
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-split-pane>
}
