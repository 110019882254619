import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private httpClient: HttpClient) {}

  getTerms(): Observable<Blob> {
    return this.httpClient.get(environment.apiUrl + '/public/pdf/terms', { responseType: 'blob' });
  }

  getConsent(): Observable<Blob> {
    return this.httpClient.get(environment.apiUrl + '/public/pdf/consent', { responseType: 'blob' });
  }
}
