import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Params, RouterLink, RouterLinkActive } from '@angular/router';
import {
  GestureController,
  IonBadge,
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterLink,
  IonRouterOutlet,
  IonSplitPane,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular/standalone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import {
  albums,
  bag,
  caretDown,
  caretUp,
  cloudDone,
  cloudOffline,
  cog,
  cube,
  documentText,
  documents,
  football,
  help,
  home,
  information,
  logOut,
  mail,
  moon,
  notifications,
  people,
  person,
  reader,
  settings,
  statsChart,
  sunny,
} from 'ionicons/icons';
import { firstValueFrom } from 'rxjs';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { PictureRoundedComponent } from 'src/app/components/picture-rounded/picture-rounded.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DatabaseService } from 'src/app/services/database/database.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { OfflineService } from 'src/app/services/offline/offline.service';
import { ThemeEnum, ThemeService } from 'src/app/services/theme/theme.service';
import { PopoverUserProfileComponent } from '../../components/popover-user-profile/popover-user-profile.component';
import { ProfileEmailComponent } from '../../modals/profile-email/profile-email.component';
import { SurveyThanksComponent } from '../../modals/survey-thanks/survey-thanks.component';
import { NotificationType } from '../../models/notification.model';
import { PictureModel } from '../../models/picture.model';
import { AuthoritiesEnum, User } from '../../models/users/user.model';
import { AuthorityVerificationService } from '../../services/authority-verification/authority-verification.service';
import { CguTermsOpenerService } from '../../services/cgu-terms-opener/cgu-terms-opener.service';
import { ErrorService } from '../../services/error/error.service';
import { Events } from '../../services/events/events.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ProfileService } from '../../services/profile/profile.service';
import { RequestService } from '../../services/request/request.service';

type CategoryRoutes = {
  title: string | null;
  icon: string;
  opened: boolean;
  routes: Array<MenuRoute>;
};

type MenuRoute = {
  title: string;
  url: string;
  icon: string;
  queryParams?: Params;
  customIcon?: boolean;
  disabledOffline?: boolean;
};

@Component({
  selector: 'app-tabs',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    IonItemDivider,
    IonItemGroup,
    IonButton,
    IonImg,
    IonRouterLink,
    IonRouterOutlet,
    IonItem,
    RouterLinkActive,
    RouterLink,
    IonNote,
    IonListHeader,
    IonList,
    IonIcon,
    IonBadge,
    IonSplitPane,
    IonMenuToggle,
    IonContent,
    IonMenu,
    IonLabel,
    TranslateModule,
    HeaderComponent,
    PictureRoundedComponent,
  ],
})
export class MenuPage implements OnInit, OnDestroy, AfterViewInit {
  account: User;
  notificationLength: number;
  AuthoritiesEnum = AuthoritiesEnum;
  AuthorityVerification = AuthorityVerificationService;
  accountImage: PictureModel = { content: '' };
  redirectAdmin: boolean;
  static SplitPaneVisible: boolean;

  public webMenuRoutes: CategoryRoutes[] = [
    {
      title: null,
      icon: '',
      opened: true,
      routes: [
        { title: 'NOTIFICATIONS', url: '/tabs/notifications', icon: 'notifications' },
        { title: 'EVENTS', url: '/tabs/home', icon: 'home' },
      ],
    },
    {
      title: null,
      icon: '',
      opened: true,
      routes: [
        // { title: 'BAG', url: '/tabs/bag', icon: 'bag' },
        { title: 'PRODUCTS', url: '/tabs/admin/product', icon: 'cube' },
      ],
    },
    {
      title: null,
      icon: '',
      opened: true,
      routes: [{ title: 'USERS', url: '/tabs/admin/profile-admin', icon: 'people' }],
    },
    /*
    {
      title: 'REPORTS',
      icon: 'stats-chart',
      opened: false,
      routes: [
        {
          title: 'REPORTS_FROM_SURVEY',
          url: '/tabs/admin/survey-admin',
          queryParams: { reportBy: 'survey' },
          icon: 'stats-chart',
        },
        {
          title: 'REPORTS_FROM_PRODUCT',
          url: '/tabs/admin/survey-admin',
          queryParams: { reportBy: 'product' },
          icon: 'stats-chart',
        },
      ],
    },
    */
    {
      title: 'SETTINGS',
      icon: 'cog',
      opened: false,
      routes: [
        { title: 'SURVEY_TEMPLATES', url: '/tabs/admin/survey-template', icon: 'documents' },
        { title: 'CRITERIA_SETS', url: '/tabs/admin/criteria-set', icon: 'documents' },
        { title: 'CRITERIA', url: '/tabs/admin/criteria', icon: 'documents' },
        { title: 'COMPLEMENTARY_INFO_LIGHT_PLURIAL', url: '/tabs/complementary-info', icon: 'information' },
        { title: 'BRANDS', url: '/tabs/brand', icon: '/assets/icons/salomon.svg', customIcon: true },
        { title: 'CATEGORIES', url: '/tabs/category', icon: 'albums' },
        { title: 'SPORTS', url: '/tabs/admin/sport', icon: 'football' },
        { title: 'QUESTION_TYPE', url: '/tabs/admin/question-type-sport', icon: 'help' },
        { title: 'UPDATE_CGU', url: '/tabs/admin/update-cgu', icon: 'document-text' },
      ],
    },
  ];
  public mobileMenuRoutes: MenuRoute[] = [
    // TODO: bag mobile page
    {
      title: 'NOTIFICATIONS',
      url: '/tabs/notifications',
      icon: 'notifications',
      disabledOffline: true,
    },
    {
      title: 'MY_PROFILE',
      url: '/tabs/profile',
      icon: 'person',
      disabledOffline: true,
    },
    { title: 'EVENTS', url: '/tabs/home', icon: 'home' },
    {
      title: 'CONTACT_REFERENT',
      url: '/tabs/contact-referent',
      icon: 'mail',
      disabledOffline: true,
    },
  ];

  constructor(
    public navCtrl: NavController,
    public platform: Platform,
    protected events: Events,
    public authorityVerification: AuthorityVerificationService,
    public notificationService: NotificationService,
    private popoverCtrl: PopoverController,
    public profileService: ProfileService,
    private modalCtrl: ModalController,
    private requestService: RequestService,
    private errorService: ErrorService,
    private cguTermsOpener: CguTermsOpenerService,
    private authService: AuthService,
    private languageService: LanguageService,
    private themeService: ThemeService,
    public offlineService: OfflineService,
    private databaseService: DatabaseService,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
    private gestureCtrl: GestureController,
    private cdRef: ChangeDetectorRef,
  ) {
    addIcons({
      notifications,
      home,
      bag,
      person,
      people,
      cube,
      statsChart,
      reader,
      help,
      football,
      caretDown,
      caretUp,
      logOut,
      information,
      mail,
      albums,
      documents,
      documentText,
      moon,
      sunny,
      cloudOffline,
      cloudDone,
      settings,
      cog,
    });
  }

  ngAfterViewInit(): void {
    if (!this.platform.is('capacitor')) {
      let workingWidth = 300;
      let startingWidth = 300;

      const splitPane = document.querySelector('ion-split-pane')!;
      const splitPaneDivider = document.querySelector('div.divider')!;
      const gesture = this.gestureCtrl.create({
        gestureName: 'resize-menu',
        onMove: (detail) => {
          requestAnimationFrame(() => {
            workingWidth = startingWidth + detail.deltaX;
            splitPane.style.setProperty('--side-width', `${workingWidth}px`);
            this.cdRef.detectChanges();
          });
        },
        onEnd: () => {
          startingWidth = workingWidth;
          this.cdRef.detectChanges();
        },
        el: splitPaneDivider,
      });
      gesture.enable(true);
    }
  }

  async ngOnInit() {
    const account = this.authService.currentUser;
    if (account && account.id) {
      this.account = account;
      try {
        await this.languageService.setupLanguage();
        const picture = await firstValueFrom(this.profileService.getProfilePictureMin(account.id));
        if (picture) this.accountImage = picture;
        else this.accountImage.content = 'assets/img/account-default.png';
      } catch (err) {
        console.error(err);
      }
    } else console.error('undefined user');

    this.events.subscribe('notification:received', (data) => {
      if (data != null && data.isAppInFocus) {
        this.updateNotificationBadge();
      }
    });

    this.events.subscribe('notifications:length', (length) => {
      this.notificationLength = length;
    });

    this.events.subscribe('profilePicture', (profilePicture) => {
      this.accountImage = profilePicture;
    });

    this.events.subscribe('tabs-toEventDetailsPage', (event) => {
      this.navCtrl.navigateRoot('/tabs/home').then(() => {
        this.events.publish('open-event', event);
      });
    });

    this.events.subscribe('tabs-toSurveySummaryPage', (data) => {
      this.navCtrl.navigateRoot('/tabs/home').then(() => {
        this.events.publish('open-survey-summary', data);
      });
    });

    this.events.subscribe('tabs-toProfileAdminPage', (data) => {
      if (
        this.authorityVerification.hasS2ALocalRole(this.account) ||
        this.authorityVerification.hasTestingManagerRole(this.account)
      ) {
        this.navCtrl.navigateRoot('/tabs/admin/profile-admin').then(() => {
          this.events.publish('adminUsers-redFromNotification');
        });
      }
    });

    this.events.subscribe('tabs-toProductFeedbackPageAthlete', (data) => {
      if (this.AuthorityVerification.getHighLevelAuthority(this.account.authorities) == AuthoritiesEnum.ATHLETE) {
        this.navCtrl.navigateRoot(`/tabs/${this.platform.is('capacitor') ? 'bag-mobile' : 'bag'}`).then(() => {
          this.events.publish('open-product-feedback', data);
        });
      }
    });

    this.events.subscribe('tabs-toProductFeedbackPageAdmin', (data) => {
      if (this.authorityVerification.hasS2ALocalRole(this.account) && !this.platform.is('capacitor')) {
        this.navCtrl.navigateRoot('/tabs/bag').then(() => {
          this.events.publish('open-product-feedback', data);
        });
      }
    });

    this.events.subscribe('tabs-toProfilePage', () => {
      this.navCtrl.navigateRoot('/tabs/profile');
    });

    this.events.subscribe('survey-thanks', async (plurial) => {
      const modal = await this.modalCtrl.create({
        component: SurveyThanksComponent,
        componentProps: {
          plurial,
        },
      });
      await modal.present();
    });

    this.events.subscribe('to-welcome-notification-check', () => {
      this.notificationService.getUserNotifications().subscribe({
        next: (notifications) => {
          if (
            notifications &&
            notifications.length == 1 &&
            notifications[0].type == NotificationType.WELCOME &&
            !notifications[0].read
          ) {
            this.navCtrl.navigateRoot('/tabs/notifications').then(() => {
              this.events.publish('open-welcome', notifications);
            });
          }
        },
        error: (err) => console.error(err),
      });
    });

    if (this.redirectAdmin) {
      // Il y a redirect-user-admin-page dans l'URL, on redirige l'utilisateur vers la page d'admin users s'il a les droits
      this.events.publish('tabs-toProfileAdminPage');
    }

    this.updateNotificationBadge();
  }

  ngOnDestroy() {
    this.events.unsubscribe('notification:received');
    this.events.unsubscribe('notification:opened');
    this.events.unsubscribe('notifications:length');
    this.events.unsubscribe('header');
    this.events.unsubscribe('loading-on');
    this.events.unsubscribe('loading-off');
    this.events.unsubscribe('profilePicture');
    this.events.unsubscribe('toEventDetailsPage');
    this.events.unsubscribe('to-welcome-notification-check');
    this.events.unsubscribe('tabs-toEventDetailsPage');
    this.events.unsubscribe('tabs-toProfileAdminPage');
    this.events.unsubscribe('tabs-toProductFeedbackPageAthlete');
    this.events.unsubscribe('tabs-toProductFeedbackPageAdmin');
    this.events.unsubscribe('tabs-toProfilePage');
    this.events.unsubscribe('unauthorized-error');
    this.events.unsubscribe('survey-thanks');
  }

  updateNotificationBadge() {
    this.events.publish('howManyNotifications');
  }

  async clickUserProfile(event: Event) {
    const popover = await this.popoverCtrl.create({
      component: PopoverUserProfileComponent,
      event,
      size: 'auto',
      alignment: 'end',
      side: 'bottom',
    });

    this.events.subscribe('updateProfilePage', () => {
      popover.dismiss();
      this.navCtrl.navigateRoot('/tabs/profile');
    });

    this.events.subscribe('logout', async () => {
      popover.dismiss();
      await this.authService.logout();
    });

    this.events.subscribe('contactReferent', async () => {
      popover.dismiss();
      try {
        if (this.account.id) {
          const requests = await firstValueFrom(this.requestService.getRequestsByUserId(this.account.id));
          if (requests.length == 0) {
            this.modalCtrl
              .create({
                component: ProfileEmailComponent,
                backdropDismiss: true,
                componentProps: {
                  isModal: true,
                },
              })
              .then((modal) => {
                modal.present().then();
              });
          } else {
            this.errorService.displayErrorMessage(null, 'REQUEST_EMIT_ERROR');
          }
        }
      } catch (err) {
        this.errorService.displayErrorMessage(null, 'REQUEST_EMIT_ERROR');
        console.error(err);
      }
    });

    popover.onDidDismiss().then(({ data }) => {
      this.events.unsubscribe('updateProfilePage');
      this.events.unsubscribe('logout');
      this.events.unsubscribe('contactReferent');
    });

    popover.present().catch((error) => {
      console.error(error);
    });
  }

  toggleCategoryOpened(category: CategoryRoutes) {
    category.opened = !category.opened;
  }

  categoryHasPermission(category: CategoryRoutes) {
    return category.routes.some((r) => this.routeHasPermission(r.url, r.queryParams));
  }

  routeHasPermission(url: string, params: any): boolean {
    const account = this.authService.currentUser!;
    switch (url) {
      case '/tabs/bag':
        return (
          this.AuthorityVerification.getHighLevelAuthority(account.authorities) == AuthoritiesEnum.ATHLETE ||
          this.authorityVerification.hasS2ALocalRole(account)
        );
      case '/tabs/admin/product':
        return (
          this.authorityVerification.hasS2ALocalRole(account) ||
          this.authorityVerification.hasTestingManagerRole(account)
        );
      case '/tabs/admin/profile-admin':
        return (
          this.authorityVerification.hasTestingManagerRole(account) ||
          this.authorityVerification.hasS2ALocalRole(account)
        );
      case '/tabs/admin/survey-admin':
        if (params.reportBy === 'survey')
          return (
            this.authorityVerification.hasTestingManagerRole(account) ||
            this.authorityVerification.hasS2ALocalRole(account)
          );
        else return this.authorityVerification.hasS2ALocalRole(account);
      case '/tabs/admin/survey-template':
        return this.authorityVerification.hasTestingAdminRole(account);
      case '/tabs/admin/criteria-set':
        return this.authorityVerification.hasTestingAdminRole(account);
      case '/tabs/admin/criteria':
        return this.authorityVerification.hasTestingAdminRole(account);
      case '/tabs/complementary-info':
        return this.authorityVerification.hasAdminRole(account);
      case '/tabs/brand':
        return this.authorityVerification.hasAdminRole(account);
      case '/tabs/category':
        return this.authorityVerification.hasAdminRole(account);
      case '/tabs/admin/sport':
        return this.authorityVerification.hasAdminRole(account);
      case '/tabs/admin/question-type-sport':
        return this.authorityVerification.hasAdminRole(account);
      case '/tabs/admin/update-cgu':
        return this.authorityVerification.hasAdminRole(account);
      default:
        return true;
    }
  }

  toTermsOfUse() {
    this.cguTermsOpener.termsOfUse();
  }

  toPrivacyPolicy() {
    this.cguTermsOpener.privacyPolicy();
  }

  logoutUser() {
    this.authService.logout();
  }

  async switchTheme() {
    try {
      await this.themeService.switchTheme();
    } catch (err) {
      console.error(err);
    }
  }

  async switchMode() {
    if (this.databaseService.populateInProgress) {
      const value = await firstValueFrom(this.translateService.get('OFFLINE_DATA_IN_PROGRESS'));
      const toast = await this.toastCtrl.create({
        position: 'top',
        swipeGesture: 'vertical',
        message: value,
        duration: 4000,
      });
      await toast.present();
    } else await this.offlineService.switchMode();
  }

  toggleSplitPaneVisible(event: any) {
    const visible: boolean = event.detail.visible;
    MenuPage.SplitPaneVisible = visible;
  }

  get isDark(): boolean {
    return ThemeService.currentTheme === ThemeEnum.DARK;
  }

  get splitPaneVisible(): boolean {
    return MenuPage.SplitPaneVisible;
  }

  get isOffline(): boolean {
    return OfflineService.isOffline;
  }

  get isConnected(): boolean {
    return NetworkService.isConnected;
  }
}
