import { User } from './users/user.model';

export interface Request {
  id: number | null;
  content: string;
  sender: User | null;
  archived: boolean;
  createdDate: Date;
  requestType: RequestType | null;
}

export enum RequestType {
  CHANGE_INFO = 'CHANGE_INFO',
  ACCOUNT_DELETION = 'ACCOUNT_DELETION',
  OTHER = 'OTHER',
}
