import { Injectable } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular/standalone';
import { saveAs } from 'file-saver';

export type ContentType = {
  type: string;
};

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  constructor(private platform: Platform) {}

  public openCsv(result: BlobPart, title: string) {
    const blob = new Blob([result], { type: 'attachment/csv;charset=utf-8' });
    this.save(blob, title + '.csv', 'attachment/csv;charset=utf-8');
  }

  public openExcel(data: BlobPart, title: string) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    this.save(blob, title + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  public async openPdf(blob: Blob, title: string) {
    // const blob = new Blob([data], { type: 'application/pdf' });
    await this.save(blob, title + '.pdf', 'application/pdf');
  }

  private async save(blob: Blob, filename: string, format: string) {
    if (!this.platform.is('capacitor')) {
      saveAs(blob, filename);
    } else {
      try {
        const result = await Filesystem.writeFile({
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
          path: filename,
          data: blob,
        });
        await FileOpener.open({
          filePath: result.uri,
          contentType: format,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
