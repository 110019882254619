<ion-list>
  <ion-item class="item item-md" (click)="publish('updateProfilePage')">
    <ion-label>
      <span>{{ 'MY_PROFILE' | translate }}</span>
    </ion-label>
    <ion-icon color="dark" name="person"></ion-icon>
  </ion-item>
  <ion-item class="item item-md" (click)="publish('contactReferent')">
    <ion-label>
      <span>{{ 'CONTACT_REFERENT' | translate }}</span>
    </ion-label>
    <ion-icon color="dark" name="mail"></ion-icon>
  </ion-item>
  <ion-item class="item item-md" (click)="switchTheme()">
    <ion-label>
      <span>{{ 'SWITCH_THEME' | translate }}</span>
    </ion-label>
    <ion-icon color="dark" [name]="isDark ? 'moon' : 'sunny'"></ion-icon>
  </ion-item>
  <ion-item class="item item-md" (click)="publish('logout')">
    <ion-label>
      <span>{{ 'LOGOUT' | translate }}</span>
    </ion-label>
    <ion-icon color="dark" name="log-out"></ion-icon>
  </ion-item>
</ion-list>
