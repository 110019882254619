import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular/standalone';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private translateService: TranslateService,
    private toastCtrl: ToastController,
  ) {}

  displayErrorMessage(error: null | HttpErrorResponse, defaultKey: string) {
    let params = [];
    let key: string | null = null;
    if (error instanceof HttpErrorResponse && error.status === 400) {
      // cas du delete questiontype
      if (typeof error.error === 'string') {
        const newerror = JSON.parse(error.error);
        key = newerror.message;
        params = newerror.params;
      } else {
        key = error.error.message;
        params = error.error.params;
      }
    }
    if (!key) {
      key = defaultKey;
    }
    this.translateService.get(key, params).subscribe(async (value) => {
      const toast = await this.toastCtrl.create({
        message: value,
        duration: 4000,
        position: 'top',
        swipeGesture: 'vertical',
      });
      await toast.present();
    });
  }
}
