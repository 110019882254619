import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import { arrowBack, close, cloudOffline, home } from 'ionicons/icons';
import { MenuPage } from 'src/app/pages/menu/menu.page';
import { ThemeEnum, ThemeService } from 'src/app/services/theme/theme.service';
import { isMobileOffline, isNetworkAvailable } from 'src/app/utils/offline.utils';

export enum HeaderIconType {
  IonIcon,
  IcoMoon,
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    IonSearchbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonToolbar,
    IonHeader,
    IonSpinner,
    IonButton,
    IonIcon,
    IonMenuButton,
    TranslateModule,
  ],
})
export class HeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() backButton: boolean = false;
  @Input() backUrl: string;
  @Input() backOverride: boolean;
  @Input('isModal') isModal: boolean = false;
  @Input('modalDismissable') modalDismissable: boolean = true;
  @Output() backPressed = new EventEmitter();

  isHome: boolean;

  constructor(
    public platform: Platform,
    public navCtrl: NavController,
    private router: Router,
    public modalCtrl: ModalController,
  ) {
    addIcons({ arrowBack, close, cloudOffline, home });
  }

  get isConnected(): boolean {
    return isNetworkAvailable(this.platform);
  }

  get splitPaneVisible(): boolean {
    return MenuPage.SplitPaneVisible;
  }

  get isDark(): boolean {
    return ThemeService.currentTheme === ThemeEnum.DARK;
  }

  ngOnInit(): void {
    this.isHome = this.router.url === '/tabs/home';
  }

  goBack(): void {
    if (this.backOverride) {
      this.backPressed.emit();
    } else {
      if (this.backUrl) this.navCtrl.navigateBack(this.backUrl);
      else this.navCtrl.back();
    }
  }

  goHome(): void {
    this.navCtrl.navigateRoot('/tabs/home');
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

  isOffline(): boolean {
    return isMobileOffline(this.platform);
  }
}
