import { Injectable } from '@angular/core';
import { User } from '../../models/users/user.model';
import { Request } from '../../models/request.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private httpClient: HttpClient) {}

  addRequest(request: Request): Observable<Request> {
    return this.httpClient.post<Request>(environment.apiUrl + '/request', request);
  }

  getRequestsByUserId(userId: number): Observable<Request[]> {
    return this.httpClient.get<Request[]>(environment.apiUrl + `/request/${userId}`);
  }

  archiveRequest(requestId: number): Observable<Request> {
    return this.httpClient.get<Request>(environment.apiUrl + `/request/${requestId}/archive`);
  }

  anonymiseRequest(userId: number): Observable<User> {
    return this.httpClient.get<User>(environment.apiUrl + `/request/${userId}/anonymize`);
  }
}
