import { Auditable } from './auditable.model';
import { PictureModel } from './picture.model';

export class Notification {
  id: number;
  title: string;
  message: string;
  icon: string;
  color: string;
  userId: number;
  dataId: number;
  userIdFrom: number;
  read: boolean;
  imageContent: PictureModel;
  auditable: Auditable;
  date: Date;
  answered: boolean;
  type: NotificationType; //Le type détermine la page à rediriger et l'icone à afficher dans la page de notifications
  isDeleting = false; // front seulement, permet d'indiquer si on est en train de supprimer une notification
  timeoutDeleteIndex: number; // front seulement, permet de lier le timeout de la suppression
  drag: any; // front seulement, permet de lier avec le drag utilisateur
  isAccepting = false; // front seulement, permet d'indiquer si on est en train de répondre oui à une notification
  isDeclining = false; // front seulement, permet d'indiquer si on est en train de répondre non à une notification

  extractNote() {
    const result = {
      text: this.message,
      note: '',
    };

    if (this.message != null) {
      const extract = this.message.match(/\{\{note:([0-9])*\}\}/);
      if (extract != null && extract[1] != null && extract[1] != '') {
        result.note = extract[1];
        result.text = this.message.substring(0, this.message.indexOf('-'));
      }
    }

    return result;
  }
}

export enum NotificationType {
  EVENT_SURVEY = 'EVENT_SURVEY',
  FILL_SURVEY_REMINDER = 'FILL_SURVEY_REMINDER',
  SURVEY_COMPLETED = 'SURVEY_COMPLETED',
  FEEDBACK_PRODUCT = 'FEEDBACK_PRODUCT',
  NEW_ACCOUNT = 'NEW_ACCOUNT',
  WELCOME = 'WELCOME',
  PERSONAL_DATA_CHANGED = 'PERSONAL_DATA_CHANGED',
}
