<app-header [isModal]="isModal" [modalDismissable]="isModal" [backButton]="false" [title]="getPageTitle()"></app-header>

<ion-content
  class="ion-padding"
  no-bounce
  has-bouncing="false"
  class="edition"
  [class.mobile]="platform.is('capacitor')"
>
  @if (loading) {
    <ion-spinner></ion-spinner>
  } @else if (request_pending) {
    <ion-item>
      <ion-label style="font-size: large; font-weight: bold" class="label label-md">
        {{ 'REQUEST_EMIT_ERROR' | translate }}
      </ion-label>
    </ion-item>
  } @else {
    <div class="content-container in-modal">
      <ion-list class="my-content list list-md" text-wrap>
        <ion-radio-group [(ngModel)]="request.requestType">
          <ion-list-header class="item item-md list-header list-header-md">
            <ion-label class="label label-md"> {{ 'OBJECT' | translate }} </ion-label>
          </ion-list-header>

          <ion-item class="item item-md">
            <ion-label class="label label-md">{{ 'CHANGE_PERSONAL_INFO' | translate }}</ion-label>
            <ion-radio mode="md" slot="end" [value]="requestType.CHANGE_INFO"></ion-radio>
          </ion-item>

          <ion-item class="item item-md">
            <ion-label class="label label-md">{{ 'ACCOUNT_DELETION' | translate }}</ion-label>
            <ion-radio mode="md" slot="end" [value]="requestType.ACCOUNT_DELETION"></ion-radio>
          </ion-item>

          <ion-item class="item item-md">
            <ion-label class="label label-md">{{ 'OTHER' | translate }}</ion-label>
            <ion-radio mode="md" slot="end" [value]="requestType.OTHER"></ion-radio>
          </ion-item>
        </ion-radio-group>

        <ion-list-header class="item item-md list-header list-header-md">
          <ion-label class="label label-md"> {{ 'MESSAGE' | translate }} </ion-label>
        </ion-list-header>

        <ion-item class="item item-md">
          <ion-textarea
            autoGrow="true"
            name="content"
            class="text-area"
            type="text"
            [(ngModel)]="request.content"
            [maxlength]="255"
            [counter]="true"
          ></ion-textarea>
        </ion-item>
      </ion-list>
    </div>
  }
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <div class="button-actions" [class.ios]="platform.is('ios')" [class.mobile]="platform.is('capacitor')">
        <ion-button fill="solid" [disabled]="loading || request_pending" (click)="sendRequest()">{{
          'SEND' | translate
        }}</ion-button>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
