import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { DownloadFileService } from '../download-file/download-file.service';
import { LanguageService } from '../language/language.service';
import { ParamService } from '../param/param.service';
import { PdfService } from '../pdf/pdf.service';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class CguTermsOpenerService {
  private privacyTerms: string = '';
  private langKey: string = '';

  constructor(
    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private pdfService: PdfService,
    private downloadFileService: DownloadFileService,
    // private inAppBrowser: InAppBrowser,
    private platform: Platform,
    private paramService: ParamService,
    private languageService: LanguageService,
  ) {
    this.languageService.setupLanguage().then(() => {
      this.langKey = LanguageService.currentLang;
      this.paramService.getParamValue('PRIVACY_POLICY', this.langKey).subscribe((value: string) => {
        this.privacyTerms = value;
      });
    });
  }

  termsOfUse() {
    this.translateService.get('LOADING').subscribe(async (value) => {
      const loader = await this.loadingCtrl.create({
        message: value,
      });
      await loader.present();

      const content = await firstValueFrom(this.pdfService.getTerms());
      await this.downloadFileService.openPdf(content, 'terms_of_use');

      await loader.dismiss();
    });
  }

  async privacyPolicy() {
    if (this.privacyTerms) {
      if (this.platform.is('capacitor')) {
        await Browser.open({ url: this.privacyTerms });
      } else {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: this.privacyTerms,
        }).click();
      }
    }
  }

  consent() {
    this.translateService.get('LOADING').subscribe(async (value) => {
      const loader = await this.loadingCtrl.create({
        message: value,
      });
      await loader.present();

      const content = await firstValueFrom(this.pdfService.getConsent());
      await this.downloadFileService.openPdf(content, 'consent');

      await loader.dismiss();
    });
  }
}
