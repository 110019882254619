import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltersModel } from '../../models/paging/filters.model';
import { Page } from '../../models/paging/page';
import { PageableModel } from '../../models/paging/pageable-model';
import { PictureModel } from '../../models/picture.model';
import { ProfileAthleteManager } from '../../models/users/profile-athlete-manager.model';
import { UserExtended } from '../../models/users/user-extended.model';
import { User } from '../../models/users/user.model';
import { UserService } from '../user/user.service';
import { ProfileInterface } from './profile.interface';
import { AccountService } from '../account/account.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements ProfileInterface {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private accountService: AccountService,
    private authService: AuthService,
  ) {}

  getAllProfiles(filters: FiltersModel, pageable: PageableModel): Observable<Page<UserExtended>> {
    return this.userService.findAllUsers(filters, pageable);
  }

  getProfile(id: number): Observable<ProfileAthleteManager> {
    return this.httpClient.get<ProfileAthleteManager>(environment.apiUrl + `/profile/${id}`);
  }

  getCurrentProfile(): Observable<ProfileAthleteManager> {
    return this.httpClient.get<ProfileAthleteManager>(environment.apiUrl + '/profile');
  }

  updateProfile(user: User): Observable<ProfileAthleteManager> {
    return this.accountService.save(user).pipe(
      switchMap(() => {
        return this.httpClient.put<ProfileAthleteManager>(environment.apiUrl + '/profile', {
          athlete: user.athlete,
          manager: user.manager,
        });
      }),
    );
  }

  updateCurrentProfile(profile: ProfileAthleteManager): Observable<boolean> {
    return this.httpClient
      .put<ProfileAthleteManager>(environment.apiUrl + '/profile/currentUser', {
        athlete: profile.athlete,
        manager: profile.manager,
      })
      .pipe(
        switchMap((profile) => {
          const currentUser = this.authService.currentUser;
          currentUser!.athlete = profile.athlete;
          currentUser!.manager = profile.manager;
          return this.accountService.saveCurrent(currentUser!);
        }),
      );
  }

  setProfilePicture(userId: number, content: PictureModel): Observable<PictureModel> {
    return this.httpClient.post<PictureModel>(environment.apiUrl + `/picture/user/${userId}`, content);
  }

  updateProfilePicture(userId: number, content: PictureModel): Observable<PictureModel> {
    return this.httpClient.put<PictureModel>(environment.apiUrl + `/picture/user/${userId}`, content);
  }

  getProfilePicture(userId: number): Observable<PictureModel> {
    return this.httpClient.get<PictureModel>(environment.apiUrl + `/picture/user/${userId}`);
  }

  getProfilePictureMin(userId: number): Observable<PictureModel> {
    return this.httpClient.get<PictureModel>(environment.apiUrl + `/picture/user/min/${userId}`);
  }
}
