import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ModalController,
  IonContent,
  IonIcon,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { happyOutline } from 'ionicons/icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-survey-thanks',
  templateUrl: './survey-thanks.component.html',
  styleUrls: ['./survey-thanks.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonButtons, IonToolbar, IonContent, IonIcon, IonFooter, IonButton, TranslateModule],
})
export class SurveyThanksComponent implements OnInit {
  plurial = false;

  constructor(public modalCtrl: ModalController) {
    addIcons({ happyOutline });
  }

  ngOnInit(): void {
    const wrapper = document.getElementsByClassName('modal-wrapper');

    if (wrapper != null && wrapper.length > 0) {
      wrapper[0].className += ' survey-thanks-modal-wrapper';
    } else {
      console.warn('SurveyThanksModal : Wrapper not initialized');
    }
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
