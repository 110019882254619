import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  IonTextarea,
  ModalController,
  NavController,
  Platform,
  ToastController,
  IonToolbar,
  IonButtons,
} from '@ionic/angular/standalone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Request, RequestType } from '../../models/request.model';
import { RequestService } from '../../services/request/request.service';

import { FormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Events } from 'src/app/services/events/events.service';
import { PageAbstract } from '../../pages/PageAbstract';

@Component({
  selector: 'app-profile-email',
  templateUrl: './profile-email.component.html',
  styleUrls: ['./profile-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    IonButtons,
    IonToolbar,
    IonSpinner,
    IonFooter,
    IonContent,
    IonList,
    IonRadioGroup,
    FormsModule,
    IonListHeader,
    IonLabel,
    IonItem,
    IonRadio,
    IonTextarea,
    IonButton,
    TranslateModule,
    HeaderComponent,
  ],
})
export class ProfileEmailComponent extends PageAbstract implements OnInit {
  request: Request = {
    id: null,
    content: '',
    createdDate: new Date(),
    archived: false,
    requestType: null,
    sender: null,
  };
  requestType = RequestType;
  loading: boolean = false;
  request_pending: boolean = false;
  isModal: boolean = false;

  constructor(
    events: Events,
    private requestService: RequestService,
    private translateService: TranslateService,
    private toastCtrl: ToastController,
    private authService: AuthService,
    public navCtrl: NavController,
    public platform: Platform,
    public modalCtrl: ModalController,
  ) {
    super(events);
  }

  async ngOnInit() {
    this.loading = true;

    try {
      const user = this.authService.currentUser;
      if (user) {
        this.request.sender = user;
        if (user.id) {
          const requests = await firstValueFrom(this.requestService.getRequestsByUserId(user.id));
          if (requests.length != 0) this.request_pending = true;
        } else console.error('null user id');
      } else console.error('undefined user');
    } catch (err) {
      console.error(err);
      this.request_pending = true;
    } finally {
      this.loading = false;
    }
  }

  getPageTitle(): string {
    return 'CONTACT_REFERENT';
  }

  async sendRequest() {
    this.request.createdDate = new Date();
    let error = null;

    if (!this.request.requestType) {
      error = 'REQUEST_TYPE_ERROR';
    } else if (!this.request.content || this.request.content === '') {
      error = 'REQUEST_CONTENT_NULL_ERROR';
    } else if (this.request.content.length > 255) {
      error = 'REQUEST_CONTENT_LENGTH_ERROR';
    }

    if (error != null) {
      this.translateService.get(error).subscribe(async (value) => {
        const toast = await this.toastCtrl.create({
          message: value,
          duration: 4000,
          position: 'top',
          swipeGesture: 'vertical',
        });
        await toast.present();
      });
    } else {
      try {
        const request = await firstValueFrom(this.requestService.addRequest(this.request));
        this.translateService.get('REQUEST_SENT').subscribe(async (value) => {
          const toast = await this.toastCtrl.create({
            message: value,
            duration: 4000,
            position: 'top',
            swipeGesture: 'vertical',
          });
          await toast.present();
        });
        if (this.platform.is('capacitor')) this.navCtrl.back();
        else await this.modalCtrl.dismiss();
      } catch (err) {
        console.error(err);
      }
    }
  }

  back() {
    this.modalCtrl.dismiss();
  }
}
