import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IonIcon, IonItem, IonLabel, IonList, IonToggle } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import { logOut, mail, moon, person, sunny, documentText } from 'ionicons/icons';
import { ThemeEnum, ThemeService } from 'src/app/services/theme/theme.service';
import { Events } from '../../services/events/events.service';

@Component({
  selector: 'app-popover-user-profile',
  templateUrl: './popover-user-profile.component.html',
  styleUrls: ['./popover-user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonToggle, IonList, IonItem, IonLabel, IonIcon, TranslateModule],
})
export class PopoverUserProfileComponent {
  isDark: boolean = ThemeService.currentTheme === ThemeEnum.DARK;

  constructor(
    public events: Events,
    private themeService: ThemeService,
  ) {
    addIcons({ person, mail, logOut, moon, sunny, documentText });
  }

  publish(action: string) {
    this.events.publish(action);
  }

  async switchTheme() {
    try {
      await this.themeService.switchTheme();
      this.isDark = !this.isDark;
    } catch (err) {
      console.error(err);
    }
  }
}
