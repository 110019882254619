<ion-header class="header">
  <ion-toolbar>
    <ng-content select="[events-download]"></ng-content>

    <ion-buttons slot="start">
      @if (backButton) {
        <ion-button (click)="goBack()">
          <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
        </ion-button>
      } @else if (!isHome && this.platform.is('capacitor') && !isModal) {
        <ion-button (click)="goHome()">
          <ion-icon slot="icon-only" name="home"></ion-icon>
        </ion-button>
      }
    </ion-buttons>

    <ng-content select="[events-actions]"></ng-content>

    <ion-title [style.font-size]="platform.is('capacitor') && title.length > 10 ? 'small' : 'medium'">{{
      title | translate
    }}</ion-title>

    <ion-buttons slot="end">
      @if (!isModal) {
        @if (isOffline()) {
          <ion-icon [color]="isConnected ? 'success' : 'danger'" name="cloud-offline"></ion-icon>
        }
        @if (splitPaneVisible) {
          <img id="logo" [src]="isDark ? 'assets/img/logo-white.svg' : 'assets/img/logo-dark.svg'" alt="Logo Salomon" />
        }
        <ion-menu-button></ion-menu-button>
      } @else if (isModal && modalDismissable) {
        <ion-button (click)="dismiss()">
          <ion-icon class="icon-only" name="close"></ion-icon>
        </ion-button>
      }
    </ion-buttons>
  </ion-toolbar>

  <ng-content select="[events-toolbar]"></ng-content>
  <ng-content select="[search-toolbar]"></ng-content>
</ion-header>
