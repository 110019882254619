<ion-content class="ion-padding" class="modal survey-thanks-modal">
  <div style="height: 40%; width: 100%">
    <ion-icon style="height: 100%; width: 100%" name="happy-outline"></ion-icon>
  </div>
  <div style="height: 40%; width: 100%">
    <h1 style="text-align: center">{{ 'THANKS_FILLING_SURVEY' + (plurial ? 'S' : '') | translate }}</h1>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <div class="button-actions">
        <ion-button color="dark" fill="outline" (click)="closeModal()">{{ 'YOU_ARE_WELCOME' | translate }}</ion-button>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
